//export const APIURL = 'http://localhost:80'
export const ENDPOINT = "https://notifyskylot44.net";
export const APIURL = 'https://100lankip2.com'
/*
LINE = 2005400651
ID CHA = 5e884dbe2304c33be898c765d182c076
*/
/*
export const WEBTOKEN = 'webdemo'
export const WEBNAME = 'webdemo'
export const WEBFULLNAME = 'webdemo'
export const WEBCASINO = 'https://www.user44.com'
export const VDO = {
  open:1,
  url:'play.mp4'
}
*/

export const WEBTOKEN = 'skylot44'
export const WEBNAME = 'skylot44'
export const WEBFULLNAME = 'skylot44'
export const WEBCASINO = 'https://www.user44.com'
export const VDO = {
  open:1,
  url:'playnew.mp4',
  urlall:'https://youtu.be/zK1-hbGeMX8',
  urlall2:'https://youtu.be/rqat8QB1UlM'
}


/*
export const WEBTOKEN = 'mom147'
export const WEBNAME = 'mom147'
export const WEBFULLNAME = 'mom147'
export const WEBCASINO = 'https://www.user147.com'
export const VDO = {
  open:1,
  url:'play.mp4'
}

/*
export const WEBTOKEN = 'daddy44'
export const WEBNAME = 'daddy44'
export const WEBFULLNAME = 'daddy44'
export const WEBCASINO = 'https://www.user95.com'
export const VDO = {
  open:1,
  url:'play.mp4'
}
*/
/*
export const WEBTOKEN = 'sbd19'
export const WEBNAME = 'sbd19'
export const WEBFULLNAME = 'sbd19'
export const WEBCASINO = ''
export const VDO = {
  open:1,
  url:'play.mp4'
}
*/
/*
export const WEBTOKEN = 'lot2day'
export const WEBNAME = 'lot2day'
export const WEBFULLNAME = 'lot2day'
export const WEBCASINO = ''
export const VDO = {
  open:1,
  url:'play.mp4'
}
*/
/*
export const WEBTOKEN = 'huay43'
export const WEBNAME = 'huay43'
export const WEBFULLNAME = 'huay43'
export const WEBCASINO = ''
export const VDO = {
 open:1,
  url:'play.mp4'
}
*/
/*
export const WEBTOKEN = '100lankip'
export const WEBNAME = '100lankip'
export const WEBFULLNAME = '100lankip'
export const WEBCASINO = ''
export const VDO = {
   open:1,
  url:'play.mp4'
}
*/
/*
export const WEBTOKEN = 'playlot999'
export const WEBNAME = 'playlot999'
export const WEBFULLNAME = 'playlot999'
export const WEBCASINO = ''
export const VDO = {
   open:1,
  url:'play.mp4'
}
*/
/*
export const WEBTOKEN = 'mylot789'
export const WEBNAME = 'mylot789'
export const WEBFULLNAME = 'mylot789'
export const WEBCASINO = ''
export const VDO = {
  open:1,
  url:'play.mp4'
}
*/
/*
export const WEBTOKEN = 'lot2play'
export const LG = 'thai'
export const WEBNAME = 'lot2play'
export const WEBFULLNAME = 'lot2play'
export const WEBCASINO = ''
export const VDO = {
  open:1,
  url:'play.mp4'
}
  */
export const addCommas = (nStr) => {
    nStr += ""
    const x = nStr.split(".")
    let x1 = x[0]
    const x2 = x.length > 1 ? `.${x[1]}` : ""
    const rgx = /(\d+)(\d{3})/
    while (rgx.test(x1)) {
      //x1 = x1.replace(rgx, "$1" + "," + "$2")
      x1 = x1.replace(rgx, `$1,$2`)
    }
    return x1 + x2
  }

export const checkcurrency = (name) => {
  if(name == 'th') {
    return 'THB'
  } else if(name == 'dolla'){
    return 'USD'
  } else if(name == 'kip') {
    return 'KIP'
  } else {
    return ""
  }
}


export const getBase64 = (file, cb) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function () {
    cb(reader.result)
  }
  reader.onerror = function (error) {
    console.log('Error: ', error)
  }
}

export const decodeBase64Image = (base64Str) => {
  const matches = base64Str.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/)
  const image = {}
  if (!matches || matches.length !== 3) {
    throw new Error("Invalid base64 string")
  }

  image.type = matches[1]
  image.data = matches[2]

  return image
}